import React, { useEffect, useState, useRef } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Button,
    Box,
    IconButton,
    CardMedia,
    Divider,
    TextField,
    useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from "@mui/material/styles";
import { listarAdicionais } from "../api/produtoDetalheCategoria";
import { useCart } from "../context/CartContext";

const ProductDialog = ({ open, product, onClose }) => {
    const { addItem } = useCart();
    const [adicionais, setAdicionais] = useState([]);
    const [quantidades, setQuantidades] = useState({});
    const [quantidadeProduto, setQuantidadeProduto] = useState(1);
    const [categoriaErro, setCategoriaErro] = useState(null);
    const [observacao, setObservacao] = useState(""); // Observação do produto
    const refsCategorias = useRef({});
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        if (product) {
            const fetchAdicionais = async () => {
                try {
                    const data = await listarAdicionais(product.id);
                    setAdicionais(data);
                } catch (error) {
                    console.error("Erro ao listar adicionais:", error);
                }
            };
            fetchAdicionais();
        }
    }, [product]);

    const incrementar = (id, maximo) => {
        const totalCategoria = adicionais.reduce((total, categoria) => {
            if (categoria.produtoDetalhes.some((item) => item.id === id)) {
                return categoria.produtoDetalhes.reduce(
                    (sum, item) => sum + (quantidades[item.id] || 0),
                    0
                );
            }
            return total;
        }, 0);

        if (totalCategoria < maximo) {
            setQuantidades((prev) => ({
                ...prev,
                [id]: (prev[id] || 0) + 1,
            }));
        }
    };

    const decrementar = (id) => {
        setQuantidades((prev) => ({
            ...prev,
            [id]: prev[id] > 0 ? prev[id] - 1 : 0,
        }));
    };

    const calcularTotal = () => {
        const adicionaisTotal = adicionais.reduce((total, categoria) => {
            return (
                total +
                categoria.produtoDetalhes.reduce((subTotal, item) => {
                    return subTotal + (quantidades[item.id] || 0) * item.preco;
                }, 0)
            );
        }, 0);
        return (product?.preco || 0) * quantidadeProduto + adicionaisTotal;
    };

    const validarMinimos = () => {
        for (const categoria of adicionais) {
            const totalCategoria = categoria.produtoDetalhes.reduce(
                (total, item) => total + (quantidades[item.id] || 0),
                0
            );
            if (totalCategoria < categoria.quantidade_minima) {
                setCategoriaErro(categoria.id);
                refsCategorias.current[categoria.id]?.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
                return false;
            }
        }
        return true;
    };

    const handleAdicionar = () => {
        if (validarMinimos()) {
            const adicionaisCar = [];
            adicionais.forEach((categoria) => {
                categoria.produtoDetalhes.forEach((item) => {
                    if (quantidades[item.id]) {
                        adicionaisCar.push({
                            id: item.id,
                            nome: `${categoria.nome} - ${item.nome}`,
                            preco: item.preco,
                            quantidade: quantidades[item.id],
                        });
                    }
                });
            });

            addItem({
                id: product.id,
                nome: product.nome,
                preco: product.preco,
                imagem:product.imagem,
                quantidade: quantidadeProduto,
                observacao, // Inclui a observação
                adicionais: adicionaisCar,
            });
            console.log(product)
            onClose();
        }
    };

    if (!product) return null;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen={isMobile}
            fullWidth
            maxWidth="sm"
        >
            <Box sx={{ position: "relative" }}>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        top: 16,
                        left: 16,
                        backgroundColor: "#fff",
                        zIndex: 2,
                    }}
                >
                    <ArrowBackIcon />
                </IconButton>
                <CardMedia
                    component="img"
                    image={product.imagem}
                    alt={product.nome}
                    sx={{
                        width: "100%",
                        maxHeight: 300,
                        objectFit: "cover",
                    }}
                />
            </Box>

            <DialogContent>
                <Typography variant="h6" fontWeight="bold" sx={{ mt: 2 }}>
                    {product.nome}
                </Typography>
                <Typography variant="h6" color="primary" sx={{ mt: 1 }}>
                    R$ {product.preco.toFixed(2)}
                </Typography>
                <Typography variant="body2">{product.descricao}</Typography>

                {adicionais.map((categoria) => (
                    <Box
                        key={categoria.id}
                        sx={{
                            mt: 4,
                            border:
                                categoriaErro === categoria.id
                                    ? "2px solid red"
                                    : "none",
                            borderRadius: 2,
                            p: categoriaErro === categoria.id ? 2 : 0,
                        }}
                        ref={(el) => (refsCategorias.current[categoria.id] = el)}
                    >
                        <Typography variant="h6" fontWeight="bold">
                            {categoria.nome}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Escolha de {categoria.quantidade_minima} até{" "}
                            {categoria.quantidade_maxima} opções.
                        </Typography>
                        {categoria.produtoDetalhes.map((item) => (
                            <Box
                                key={item.id}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    borderBottom: "1px solid #ddd",
                                    pb: 1,
                                    mt: 2,
                                }}
                            >
                                <Box>
                                    <Typography variant="body1">{item.nome}</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        + R$ {item.preco.toFixed(2)}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <IconButton
                                        onClick={() =>
                                            decrementar(item.id)
                                        }
                                        disabled={!quantidades[item.id]}
                                        size="small"
                                    >
                                        <RemoveIcon />
                                    </IconButton>
                                    <Typography variant="body1" sx={{ mx: 1 }}>
                                        {quantidades[item.id] || 0}
                                    </Typography>
                                    <IconButton
                                        onClick={() =>
                                            incrementar(
                                                item.id,
                                                categoria.quantidade_maxima
                                            )
                                        }
                                        size="small"
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                ))}

                {/* Campo de Observação */}
                <Box sx={{ mt: 4 }}>
                    
                    <TextField
                        fullWidth
                        placeholder="Observação"
                        value={observacao}
                        onChange={(e) => setObservacao(e.target.value)}
                        multiline
                        rows={3}
                        sx={{ mt: 2 }}
                    />
                </Box>
            </DialogContent>

            <DialogActions sx={{ p: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
                        <IconButton
                            onClick={() =>
                                setQuantidadeProduto((prev) => Math.max(prev - 1, 1))
                            }
                            size="small"
                        >
                            <RemoveIcon />
                        </IconButton>
                        <Typography variant="body1" sx={{ mx: 1 }}>
                            {quantidadeProduto}
                        </Typography>
                        <IconButton
                            onClick={() => setQuantidadeProduto((prev) => prev + 1)}
                            size="small"
                        >
                            <AddIcon />
                        </IconButton>
                    </Box>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{
                            backgroundColor: "red",
                            color: "white",
                            fontWeight: "bold",
                            borderRadius: 2,
                        }}
                        onClick={handleAdicionar}
                    >
                        Adicionar R$ {calcularTotal().toFixed(2)}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default ProductDialog;
