import { Route, Routes, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import StatusPedido from "./pages/StatusPedido";


function RoutesApp() {
    return (
        <BrowserRouter>
            <Routes>

                <Route path="/" element={<Home />} />
                <Route path="/status" element={<Home />} />
                <Route path="/pedido/:id" element={<StatusPedido/>} />
            </Routes>
        </BrowserRouter>
    )
}

export default RoutesApp;