import React, { useEffect, useState } from "react";
import { Dialog, useMediaQuery, useTheme } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import CartStep from "../../components/CartStep";
import PaymentStep from "../../components/PaymentStep";
import AddressStep from "../../components/AddressStep";
import MapStap from "../../components/MapStap";

const Cart = ({ open, onClose, onOpenProductDialog, estabelecimento,infoEntrega,setInfoEntrega }) => {
    const [step, setStep] = useState("cart"); // 'cart', 'payment', 'address', 'maps'
    const [coordenada, setCoordenada] = useState([]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handleNextStep = () => {
        if (step === "cart") setStep("address");
        else if (step === "address") setStep("maps");
        else if (step === "maps") setStep("payment");
    };

    const handlePrevStep = () => {
        if (step === "address") setStep("cart");
        else if (step === "payment") setStep("address");
        else if (step === "address") setStep("cart");
        else if (step === "maps") setStep("address");
    };

    const transition = {
        initial: { opacity: 0, y: 20 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -20 },
        transition: { duration: 0.3 }
    };

    useEffect(()=>{
        if(infoEntrega.taxa){
            console.log("taxa definida")
        }else{
            console.log("sem taxa definida")
        }
    },[infoEntrega])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen={isMobile} // Ocupa a tela inteira em dispositivos móveis
            fullWidth
            maxWidth="sm"
            sx={{
                "& .MuiPaper-root": {
                    borderRadius: isMobile ? 0 : 3,
                    p: 2,
                },
            }}
        >
            <AnimatePresence mode="wait">
                {step === "cart" && (
                    <motion.div key="cart" {...transition}>
                        <CartStep onNext={handleNextStep} estabelecimento={estabelecimento} onOpenProductDialog={onOpenProductDialog} onClose={onClose} setInfoEntrega={setInfoEntrega} infoEntrega={infoEntrega} />
                    </motion.div>
                )}
                {step === "address" && (
                    <motion.div key="address" {...transition}>
                        <AddressStep onNext={handleNextStep} onPrev={handlePrevStep} setInfoEntrega={setInfoEntrega} infoEntrega={infoEntrega} />
                    </motion.div>
                )}
                {step === "payment" && (
                    <motion.div key="payment" {...transition}>
                        <PaymentStep onClose={onClose} infoEntrega={infoEntrega} onPrev={handlePrevStep} estabelecimento={estabelecimento} />
                    </motion.div>
                )}
                {step === "maps" && (
                    <motion.div key="maps" {...transition}>
                        <MapStap onConfirm={onClose} infoEntrega={infoEntrega} setInfoEntrega={setInfoEntrega} setCoordenada={setCoordenada} onNext={handleNextStep} onPrev={handlePrevStep} />
                    </motion.div>
                )}
            </AnimatePresence>
        </Dialog>
    );
};

export default Cart;
