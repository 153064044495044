import axios from "axios";

const api = axios.create({
  baseURL: "https://api.cardapiovirtual.com/deliveryapi", // Altere conforme o endereço do backend
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
