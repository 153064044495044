import { Card, CardContent, Typography, FormControlLabel, Radio, RadioGroup, Switch, TextField, Collapse, Box, Button, IconButton } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { motion } from "framer-motion";
import { Pix, CreditCard, MonetizationOn, InfoRounded } from "@mui/icons-material";
import { useState } from "react";
import { useCart } from "../context/CartContext";
import PhoneInput from "react-phone-input-2";

import {criarPedido} from "../api/pedido"

import "react-phone-input-2/lib/material.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function PaymentStep({ onPrev, infoEntrega, estabelecimento }) {
  const navigate = useNavigate();
  const [selectedPayment, setSelectedPayment] = useState("");
  const [cardType, setCardType] = useState("credito");
  const [changeAmount, setChangeAmount] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [nomeCliente, setNomeCliente] = useState("");

  const { total, items } = useCart();
  const subtotal = total;

  const handlePaymentChange = (option) => {
    setSelectedPayment(option);
    setCardType("credito");
    setChangeAmount("");
  };

  const handleRealizarPedido = async () => {
    if (!phoneNumber || phoneNumber.length < 10) {
      toast.info("Por favor, insira um número de telefone válido.", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (nomeCliente === "") {
      toast.info("Por favor, insira seu nome.", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (!selectedPayment) {
      toast.info("Por favor, selecione uma forma de pagamento.", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    const pedido = {estabelecimento_id: estabelecimento.id,status:"AGUARDANDO",nome:nomeCliente, rua:infoEntrega.rua,numero:infoEntrega.numero,bairro:infoEntrega.bairro, cidade:infoEntrega.cidade, estado:infoEntrega.estado,cep:infoEntrega.cep, coordenada:infoEntrega.coordenadas, taxa:infoEntrega.taxa, referencia:infoEntrega.referencia, items:items,total:total, telefone:phoneNumber,forma_pagamento:{formaPagamento:selectedPayment,troco:changeAmount,tipoCartao:cardType}};
    try{
      const fazerPedido = await criarPedido(pedido)
      navigate(`/pedido/${fazerPedido.id}`);
    }catch{
      toast.info('Algo deu errado, entre em contato pelo telefone', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    
    


  };
  return (
    <Box sx={{ pb: 10, px: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <IconButton onClick={onPrev}>
          <ArrowBackIcon color="primary" />
        </IconButton>
        <Typography variant="h6" fontWeight="bold">PAGAMENTO</Typography>
        <Button color="error"></Button>
      </Box>




      <Card sx={{ maxWidth: 400, mx: "auto", mt: 4, p: 2, borderRadius: 2, boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Escolha a forma de pagamento
          </Typography>
          <div>
            {[
              { label: "Pix", value: "pix", icon: <Pix color="primary" /> },
              { label: "Cartão (maquininha)", value: "cartao", icon: <CreditCard color="primary" /> },
              { label: "Dinheiro", value: "dinheiro", icon: <MonetizationOn color="primary" /> },
            ].map((option) => (
              <motion.div whileTap={{ scale: 0.95 }} key={option.value}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={selectedPayment === option.value}
                      onChange={() => handlePaymentChange(option.value)}
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="body1" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      {option.icon} {option.label}
                    </Typography>
                  }
                  sx={{ display: "flex", justifyContent: "space-between", width: "100%", my: 1 }}
                />
              </motion.div>
            ))}
          </div>

          {/* Opções extras para cartão */}
          <Collapse in={selectedPayment === "cartao"}>
            <motion.div initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }}>
              <Typography variant="subtitle1" mt={2}>
                Escolha a opção:
              </Typography>
              <RadioGroup
                value={cardType}
                onChange={(e) => setCardType(e.target.value)}
                row
              >
                <FormControlLabel value="debito" control={<Radio color="primary" />} label="Débito" />
                <FormControlLabel value="credito" control={<Radio color="primary" />} label="Crédito" />
              </RadioGroup>
            </motion.div>
          </Collapse>

          {/* Campo de troco para dinheiro */}
          <Collapse in={selectedPayment === "dinheiro"}>
            <motion.div initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }}>
              <TextField
                label="Troco para"
                variant="outlined"
                fullWidth
                type="number"
                value={changeAmount}
                onChange={(e) => setChangeAmount(e.target.value)}
                sx={{ mt: 2 }}
              />
            </motion.div>
          </Collapse>
        </CardContent>
      </Card>
      <br /><br />
      <Box sx={{ mt: 2 }}>
        <PhoneInput
          country={'br'}
          value={phoneNumber}
          onChange={(phone) => setPhoneNumber(phone)}
          inputStyle={{ width: "100%" }}
        />
        <TextField
                label="Digite o seu nome"
                variant="outlined"
                fullWidth
                value={nomeCliente}
                onChange={(e) => setNomeCliente(e.target.value)}
                sx={{ mt: 2 }}
              />
      </Box>
      <br /><br /><br />

      <Typography variant="h6" fontWeight="bold">Resumo de valores</Typography>
      <Box sx={{ mt: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
          <Typography variant="body1">Subtotal</Typography>
          <Typography variant="body1">R$ {subtotal.toFixed(2)}</Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
          <Typography variant="body1">Taxa de entrega</Typography>
          <Typography variant="body1">R$ {infoEntrega.taxa?.toFixed(2) || "-"}</Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          <Typography variant="h6" fontWeight="bold">Total</Typography>
          <Typography variant="h6" fontWeight="bold">R$ {(subtotal + (infoEntrega.taxa || 0)).toFixed(2)}</Typography>
        </Box>
      </Box>

      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          backgroundColor: "white",
          boxShadow: "0 -2px 10px rgba(0,0,0,0.1)",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          fullWidth
          variant="contained"
          sx={{ backgroundColor: "red", color: "white", fontWeight: "bold" }}
          onClick={handleRealizarPedido}
        >
          REALIZAR PEDIDO
        </Button>
      </Box>
    </Box>
  );
}

export default PaymentStep;
