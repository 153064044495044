import React, { useState } from "react";
import { Box, Button, IconButton, Typography, TextField, Grid, Checkbox, FormControlLabel, CardMedia } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import city from "../images/cidade.png"
const mapContainerStyle = {
  width: "100%",
  height: "200px",
};

const fullMapStyle = {
  width: "100%",
  height: "400px",
};

const defaultCenter = { lat: -20.3189, lng: -40.3371 }; // Exemplo: Coordenadas padrão

function AddressStep({ onPrev, onNext, infoEntrega, setInfoEntrega }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyA-fRT7ENwqsHCN4LWWoqDchFV2gDPIoB4", // Insira sua API Key aqui
  });


  const [mapExpanded, setMapExpanded] = useState(false);
  const [markerPosition, setMarkerPosition] = useState(defaultCenter);
  const [formData, setFormData] = useState({
    numero: "",
    referencia: "",
    semNumero: false,
    semComplemento: false,
    favorito: "",
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSave = () => {
    const enderecoFinal = {
      ...infoEntrega,
      numero: formData.semNumero ? "S/N" : formData.numero,
      referencia: formData.referencia,
      coordenadas: markerPosition,
    };
    setInfoEntrega(enderecoFinal)
    console.log("Endereço salvo:", enderecoFinal);
    onNext();
  };



  return (
    <Box sx={{ pb: 10, px: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <IconButton onClick={onPrev}>
          <ArrowBackIcon color="primary" />
        </IconButton>
        <Typography variant="h6" fontWeight="bold">ENDEREÇO</Typography>
        <Button color="error">Limpar</Button>
      </Box>


      <CardMedia
        component="img"
        image={city}
        sx={{
          width: "100%",
          maxHeight: 300,
          objectFit: "cover",
        }}
      />
      {/* Endereço Resumido */}
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6">{infoEntrega.rua}</Typography>
        <Typography variant="body2" color="text.secondary">
          {infoEntrega.bairro}, {infoEntrega.cidade} - {infoEntrega.estado}
        </Typography>
      </Box>

      {/* Inputs */}
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Número casa"
            name="numero"
            variant="outlined"
            fullWidth
            value={formData.numero}
            onChange={handleChange}
          />

        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Referência"
            name="referencia"
            variant="outlined"
            fullWidth
            value={formData.referencia}
            onChange={handleChange}
          />

        </Grid>
      </Grid>



      {/* Botão Salvar Endereço */}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          backgroundColor: "white",
          boxShadow: "0 -2px 10px rgba(0,0,0,0.1)",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          fullWidth
          variant="contained"
          sx={{ backgroundColor: "red", color: "white", fontWeight: "bold" }}
          onClick={handleSave}
        >
          CONTINUAR
        </Button>
      </Box>
    </Box>
  );
}

export default AddressStep;
