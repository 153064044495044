import React, { useEffect, useState } from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    Box,
    TextField,
    CardMedia,
    Button,
    IconButton,
    BottomNavigation,
    BottomNavigationAction,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import EventIcon from "@mui/icons-material/Event";
import HomeIcon from "@mui/icons-material/Home";
import SearchIconOutlined from "@mui/icons-material/SearchOutlined";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PersonIcon from "@mui/icons-material/Person";
import ProductCard from "../../components/ProductCard";
import { listarCategoriasComItens } from "../../api/categoriaCardapio";
import ProductDialog from "../../components/ProductDialog";
import { useCart } from "../../context/CartContext";
import Cart from "../Cart/index";

function Home() {
    const { items, total } = useCart();

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [categoriaseItens, setCategoriaseItens] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [estabelecimento, setEstabelecimento] = useState({});
    const [prazo, setPrazo] = useState("");
    const [isCartOpen, setIsCartOpen] = useState(false);
    const [infoEntrega, setInfoEntrega] = useState({"cep":"","taxa":0,"rua":"","bairro":"","cidade":"","estado":""});
    const handleOpenCart = () => setIsCartOpen(true);
    const handleCloseCart = () => setIsCartOpen(false);

    useEffect(() => {
        const fetchCategoriaseItens = async () => {
            try {
                const hostname = window.location.hostname;
                const extractedSubdomain = hostname.split(".")[0];
                const data = await listarCategoriasComItens(extractedSubdomain);

                setCategoriaseItens(data.categorias);
                setFilteredItems(data.categorias);
                setEstabelecimento(data.estabelecimento);
            } catch (error) {
                console.error("Erro ao listar categorias:", error);
            }
        };

        fetchCategoriaseItens();
    }, []);

    useEffect(() => {
        if (estabelecimento?.horario?.prazo) {
            setPrazo(estabelecimento.horario.prazo);
        }
    }, [estabelecimento]);

    useEffect(() => {
        if (searchTerm) {
            const filtered = categoriaseItens.map(categoria => ({
                ...categoria,
                cardapioItens: categoria.cardapioItens.filter(item => 
                    item.nome.toLowerCase().includes(searchTerm.toLowerCase())
                )
            })).filter(categoria => categoria.cardapioItens.length > 0);
            setFilteredItems(filtered);
        } else {
            setFilteredItems(categoriaseItens);
        }
    }, [searchTerm, categoriaseItens]);

    const handleCardClick = (product) => {
        setSelectedProduct(product);
    };

    const handleClose = () => {
        setSelectedProduct(null);
    };

    return (
        <div>
            <Box>
                <AppBar position="static" color="transparent" elevation={0}>
                    <Toolbar>
                        <CardMedia
                            component="img"
                            src={estabelecimento.imagem}
                            alt="Logo"
                            sx={{ width: 40, height: 40, borderRadius: "50%" }}
                        />
                        <Box sx={{ ml: 2 }}>
                            <Typography variant="h6">{estabelecimento.nome}</Typography>
                            <Typography variant="body2" color="text.secondary">
                                Pedido mínimo R$ {estabelecimento.pedido_minimo}
                            </Typography>
                        </Box>
                    </Toolbar>
                </AppBar>

                <Box sx={{ p: 2 }}>
                    <TextField
                        fullWidth
                        placeholder="Buscar no cardápio"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <IconButton>
                                    <SearchIcon />
                                </IconButton>
                            ),
                        }}
                        variant="outlined"
                    />
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-around", p: 1 }}>
                    <Button
                        variant="outlined"
                        startIcon={<DeliveryDiningIcon />}
                        sx={{ borderRadius: 20 }}
                    >
                        Aberto
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={<EventIcon />}
                        sx={{ borderRadius: 20 }}
                    >
                        Prazo {prazo}
                    </Button>
                </Box>

                <div style={{ marginBottom: 100 }}>
                    {filteredItems.map((categoria) => (
                        <Box sx={{ p: 2 }} key={categoria.id}>
                            <Typography variant="h6">{categoria.nome}</Typography>
                            <Box>
                                {categoria.cardapioItens.map((item) => (
                                    <ProductCard
                                        key={item.id}
                                        imagem={item.imagem}
                                        title={item.nome}
                                        descricao={item.descricao}
                                        preco={item.preco}
                                        onClick={() => handleCardClick(item)}
                                    />
                                ))}
                            </Box>
                        </Box>
                    ))}
                </div>

                {items.length > 0 && (
                    <Box
                        sx={{
                            position: "fixed",
                            bottom: 0,
                            left: 0,
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            px: 1,
                            py: 1,
                            backgroundColor: "white",
                            borderTop: "1px solid #ddd",
                            zIndex: 10,
                        }}
                    >
                        <Box>
                            <Typography variant="body2">Total sem a entrega</Typography>
                            <Typography variant="h6" fontWeight="bold">
                                R$ {total.toFixed(2)} / {items.length} {items.length === 1 ? "item" : "itens"}
                            </Typography>
                        </Box>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                backgroundColor: "red",
                                color: "white",
                                fontWeight: "bold",
                                borderRadius: 2,
                            }}
                            onClick={handleOpenCart}
                        >
                            Ver Carrinho
                        </Button>
                    </Box>
                )}
            </Box>

            <ProductDialog open={!!selectedProduct} product={selectedProduct} onClose={handleClose} />
            <Cart open={isCartOpen} onClose={handleCloseCart} estabelecimento={estabelecimento} onOpenProductDialog={setSelectedProduct} infoEntrega={infoEntrega} setInfoEntrega={setInfoEntrega} />
        </div>
    );
}

export default Home;
