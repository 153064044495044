import { useParams, useNavigate } from "react-router-dom";
import { Container, Typography, Box, CircularProgress, AppBar, Toolbar, CardMedia, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { statusPedido } from "../../api/pedido";

// Configuração dos novos status com animações e textos
const pedidoStatus = {
  "AGUARDANDO": {
    texto: "Aguardando Confirmação",
    descricao: "Seu pedido foi recebido e está aguardando confirmação.",
    animacao: (
      <motion.div
        animate={{ scale: [1, 1.1, 1] }}
        transition={{ repeat: Infinity, duration: 1.2 }}
        style={{ fontSize: 50 }}
      >
        ⏳
      </motion.div>
    ),
  },
  "EM PREPARO": {
    texto: "Em Preparo",
    descricao: "Seu pedido está sendo preparado com carinho!",
    animacao: (
      <motion.div
        animate={{ rotate: [0, 15, -15, 0] }}
        transition={{ repeat: Infinity, duration: 1.5 }}
        style={{ fontSize: 50 }}
      >
        👨‍🍳
      </motion.div>
    ),
  },
  "EM ENTREGA": {
    texto: "Em Entrega",
    descricao: "Seu pedido está a caminho da sua casa!",
    animacao: (
      <motion.div
        animate={{ x: [0, 15, -15, 0] }}
        transition={{ repeat: Infinity, duration: 1.5 }}
        style={{ fontSize: 50 }}
      >
        🚚
      </motion.div>
    ),
  },
  "CONCLUIDO": {
    texto: "Entrega Concluída",
    descricao: "Seu pedido foi entregue! Aproveite sua refeição!",
    animacao: (
      <motion.div
        animate={{ scale: [1, 1.2, 1] }}
        transition={{ repeat: Infinity, duration: 1 }}
        style={{ fontSize: 50 }}
      >
        ✅
      </motion.div>
    ),
  },
  "RECUSADO": {
    texto: "Pedido Recusado",
    descricao: "Seu pedido foi cancelado. Entre em contato para mais detalhes.",
    animacao: (
      <motion.div
        animate={{ rotate: [0, -10, 10, 0] }}
        transition={{ repeat: Infinity, duration: 1.5 }}
        style={{ fontSize: 50 }}
      >
        ❌
      </motion.div>
    ),
  },
};

function StatusPedido() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [infosEstabelecimento, setInfosEstabelecimento] = useState([]);

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const resposta = await statusPedido(id);
        setStatus(pedidoStatus[resposta?.status] || null);
        setInfosEstabelecimento(resposta);
      } catch (error) {
        console.error("Erro ao buscar status do pedido:", error);
        setStatus(null);
      } finally {
        setLoading(false);
      }
    };

    fetchStatus();
    const interval = setInterval(fetchStatus, 30000);

    return () => clearInterval(interval);
  }, [id]);

  const handleVoltarCardapio = () => {
    navigate("/"); // Ajuste a rota conforme sua estrutura
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      transition={{ duration: 0.8, ease: "easeInOut" }}
    >
      <motion.div 
        initial={{ y: -50, opacity: 0 }} 
        animate={{ y: 0, opacity: 1 }} 
        transition={{ duration: 0.6, ease: "easeOut" }}
      >
        <AppBar position="static" color="transparent" elevation={0}>
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
                <CardMedia
                  component="img"
                  src={infosEstabelecimento.imagem}
                  alt="Logo"
                  sx={{ width: 40, height: 40, borderRadius: "50%" }}
                />
              </motion.div>
              <Box sx={{ ml: 2 }}>
                <Typography variant="h6">{infosEstabelecimento.nome}</Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ cursor: "pointer" }}
                  onClick={() => window.open(`https://api.whatsapp.com/send?phone=${infosEstabelecimento.telefone}`, "_blank")}
                >
                  Contato {infosEstabelecimento.telefone}
                </Typography>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </motion.div>

      <Container maxWidth="sm" sx={{ textAlign: "center", mt: 5 }}>
        <Typography 
          variant="h4" 
          gutterBottom 
          sx={{ fontWeight: "bold", color: "#333" }}
        >
          Status do Pedido
        </Typography>

        {loading ? (
          <Box>
            <CircularProgress />
            <Typography variant="h6" sx={{ mt: 2, color: "#666" }}>Carregando...</Typography>
          </Box>
        ) : status ? (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, ease: "easeOut" }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              {status.animacao}
              <Typography 
                variant="h5" 
                sx={{ mt: 2, fontWeight: "medium", color: "#444" }}
              >
                {status.texto}
              </Typography>
              <Typography 
                variant="body1" 
                color="text.secondary" 
                sx={{ mt: 1, maxWidth: 400 }}
              >
                {status.descricao}
              </Typography>
            </Box>
          </motion.div>
        ) : (
          <motion.div 
            initial={{ opacity: 0 }} 
            animate={{ opacity: 1 }} 
            transition={{ duration: 0.5 }}
          >
            <Typography variant="h6" sx={{ mt: 2, color: "red" }}>
              Pedido não encontrado
            </Typography>
          </motion.div>
        )}

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.3, ease: "easeOut" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleVoltarCardapio}
            sx={{ 
              mt: 4, 
              borderRadius: 20,
              padding: "10px 25px",
              textTransform: "none",
              fontSize: "1rem",
              boxShadow: "0 3px 5px rgba(0,0,0,0.1)"
            }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            component={motion.button}
          >
            Voltar ao Cardápio
          </Button>
        </motion.div>
      </Container>
    </motion.div>
  );
}

export default StatusPedido;