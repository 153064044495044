import React, { useState, useEffect, useRef } from "react";
import { Box, Button, CircularProgress, IconButton, Typography } from "@mui/material";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const mapContainerStyle = {
  width: "100vw",
  height: "100vh",
};

const defaultCenter = { lat: -23.55052, lng: -46.633308 }; // São Paulo

const MapScreen = ({ onNext, setCoordenada, onPrev, infoEntrega, setInfoEntrega }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyA-fRT7ENwqsHCN4LWWoqDchFV2gDPIoB4", // Substitua pela sua API Key
  });

  const mapRef = useRef(null);
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getLocationFromAddress = async (endereco) => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(endereco)}&key=AIzaSyA-fRT7ENwqsHCN4LWWoqDchFV2gDPIoB4`
        );
        const data = await response.json();

        if (data.results.length > 0) {
          setMapCenter(data.results[0].geometry.location);
        } else {
          console.warn("Endereço não encontrado. Usando localização padrão.");
        }
      } catch (error) {
        console.error("Erro ao buscar localização pelo endereço:", error);
      }
      setLoading(false);
    };

    const enderecoCompleto = `${infoEntrega.rua}, ${infoEntrega.numero} - ${infoEntrega.bairro}, ${infoEntrega.cidade} - ${infoEntrega.estado}`;
    console.log(enderecoCompleto);

    if (enderecoCompleto) {
      getLocationFromAddress(enderecoCompleto);
    } else {
      setLoading(false);
    }
  }, [infoEntrega]);

  // Função que captura a posição do centro quando o mapa se move
  const handleCenterChanged = () => {
    if (mapRef.current) {
      const newCenter = mapRef.current.getCenter();
      setMapCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
      console.log(newCenter.lat() + "," + newCenter.lng());
    }
  };

  const confirmLocalizacao = () => {
    setCoordenada(mapCenter);
    const enderecoFinal = {
      ...infoEntrega,
      coordenadas: mapCenter,
    };
    setInfoEntrega(enderecoFinal)
    onNext();

  }

  if (!isLoaded || loading) {
    return <CircularProgress sx={{ position: "absolute", top: "50%", left: "50%" }} />;
  }

  return (
    <Box sx={{ position: "relative" }}>

      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <IconButton onClick={onPrev}>
          <ArrowBackIcon color="primary" />
        </IconButton>
        <Typography variant="h6" fontWeight="bold">LOCALIZAÇÃO</Typography>
        <Button color="error"></Button>
      </Box>

      <Box sx={{ position: "relative" }}>
        {/* Mapa em tela cheia */}
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={18}
          center={mapCenter}
          options={{
            gestureHandling: "greedy",
            fullscreenControl: false,
            mapTypeControl: false,
            streetViewControl: false,
          }}
          onLoad={(map) => (mapRef.current = map)}
          onDragEnd={handleCenterChanged}
        />

        {/* Marcador fixo no centro (alinhado pela ponta inferior) */}
        <Box
          sx={{
            position: "absolute",
            top: "calc(50% - 20px)", // Move o marcador um pouco para baixo
            left: "calc(50% + 20px)",
            transform: "translate(-50%, -50%)", // Desloca para alinhar pela ponta inferior
            width: 40,
            height: 40,
            backgroundImage: "url('https://maps.gstatic.com/mapfiles/ms2/micons/red-dot.png')",
            backgroundSize: "contain",
            zIndex: 10,
          }}
        />

        {/* Botão Confirmar Localização */}
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            backgroundColor: "white",
            boxShadow: "0 -2px 10px rgba(0,0,0,0.1)",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            fullWidth
            variant="contained"
            sx={{ backgroundColor: "red", color: "white", fontWeight: "bold" }}
            onClick={() => confirmLocalizacao()}
          >
            Confirmar Localização
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MapScreen;
