import api from "./axiosInstance";

export const criarProdutoDetalheCategoria = async (dados) => {
  const response = await api.post("/produtodetalhecategoria", dados);
  return response.data;
};

export const listarProdutoDetalheCategorias = async (estabelecimento_id) => {
  const response = await api.get(`/produtodetalhecategoria/${estabelecimento_id}`);
  return response.data;
};

export const atualizarProdutoDetalheCategoria = async (id, dados) => {
  const response = await api.put(`/produtodetalhecategoria/${id}`, dados);
  return response.data;
};

export const deletarProdutoDetalheCategoria = async (id) => {
  const response = await api.delete(`/produtodetalhecategoria/${id}`);
  return response.data;
};


export const listarAdicionais = async (produto_id) => {
    const response = await api.get(`/produtodetalheadicionais/${produto_id}`);
    return response.data;
  };
  
