import api from "./axiosInstance";

export const criarPedido = async (dados) => {
  const response = await api.post("/pedido", dados);
  return response.data;
};

export const listarPedidos = async (estabelecimento_id) => {
  const response = await api.get(`/pedido/${estabelecimento_id}`);
  return response.data;
};

export const atualizarPedido = async (id, dados) => {
  const response = await api.put(`/pedido/${id}`, dados);
  return response.data;
};

export const deletarPedido = async (id) => {
  const response = await api.delete(`/pedido/${id}`);
  return response.data;
};


export const statusPedido = async (id) => {
  const response = await api.get(`/statuspedido/${id}`);
  return response.data;
};
