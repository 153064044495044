import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Button,
  IconButton,
  Divider,
  CardMedia,
  TextField,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from "@mui/material/styles";
import { useCart } from "../context/CartContext";
import { calcularDistanciaCepKm } from "../api/googleMaps";
import { toast } from "react-toastify";

const CartStep = ({ onNext, onOpenProductDialog, estabelecimento, onClose, setInfoEntrega, infoEntrega }) => {
  const { items, total, removeItem, addItem, clearCart } = useCart();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [cep, setCep] = useState("");
  const [mensagemFrete, setMensagemFrete] = useState("");
  const [loading, setLoading] = useState(false);

  const subtotal = total;

  useEffect(() => {
    console.log(items);
    if (infoEntrega.cep !== "") {
      setCep(infoEntrega.cep)
    }
  }, []);

  const incrementar = (item) => {
    if (item.adicionais.length > 0 || item.description) {
      onClose();
      onOpenProductDialog(item);
    } else {
      addItem({ ...item, quantidade: 1 });
    }
  };

  const decrementar = (item) => {
    if (item.quantidade > 1) {
      addItem({ ...item, quantidade: -1 });
    } else {
      removeItem(item.signature);
    }
  };

  const handleClearCart = () => {
    clearCart();
  };

  const handleCepChange = (event) => {
    const value = event.target.value.replace(/[^0-9]/g, "");
    setCep(value);
  };

  const calcularFrete = async () => {

    if (!cep) {
      setMensagemFrete("Por favor, insira um CEP válido.");
      toast.info('Por favor, insira um CEP válido.', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    try {
      setLoading(true)
      const distancia = await calcularDistanciaCepKm({
        estabelecimento_id: estabelecimento.id,
        cep_cliente: cep,
      });
      let taxa = distancia.taxa;
      if (taxa === 999) {
        setInfoEntrega({ "taxa": 999, "coordenada": "0", "cep": cep })
        setMensagemFrete("Desculpe, não realizamos entregas para esta região.");
      } else {
        setInfoEntrega({ "taxa": parseFloat(taxa), "coordenada": "0", "cep": cep, "rua": distancia.endereco_cliente.rua, "numero": "", "bairro": distancia.endereco_cliente.bairro, "cidade": distancia.endereco_cliente.cidade, "estado": distancia.endereco_cliente.estado })
        setMensagemFrete("");
      }
    } catch (error) {
      console.error("Erro ao calcular frete:", error);
      setMensagemFrete("Erro ao calcular frete. Tente novamente.");
    }
    setLoading(false)
  };


  const continuar = () => {
    if (items.length == 0) {
      toast.info('Por favor, adicione um item no carrinho.', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (!loading && infoEntrega.rua != "") {
      onNext()
    } else {
      setMensagemFrete("Por favor, insira um CEP válido.");
      toast.info('Por favor, insira um CEP válido.', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }



  return (
    <Box sx={{ pb: 10 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <IconButton onClick={onClose}>
          <ArrowBackIcon color="primary" />
        </IconButton>
        <Typography variant="h6" fontWeight="bold">SACOLA</Typography>
        <Button onClick={handleClearCart} color="error">Limpar</Button>
      </Box>

      <Typography variant="h6" fontWeight="bold">Itens adicionados</Typography>
      <Box>
        {items.map((item) => (
          <Box
            key={item.signature}
            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #ddd", py: 2 }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CardMedia
                component="img"
                src={item.imagem || "https://via.placeholder.com/50"}
                alt={item.nome}
                sx={{ width: 50, height: 50, borderRadius: 2, mr: 2 }}
              />
              <Box>
                <Typography variant="body1" fontWeight="bold">{item.nome}</Typography>
                <Typography variant="body2" color="text.secondary">{item.adicionais.map((adicional) => adicional.nome).join(", ")}</Typography>
                <Typography variant="h6" fontWeight="bold">
                  R$ {((item.preco + item.adicionais.reduce((sum, adicional) => sum + adicional.preco * adicional.quantidade, 0)) * item.quantidade).toFixed(2)}
                </Typography>

              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton onClick={() => decrementar(item)}><RemoveIcon /></IconButton>
              <Typography variant="body1" sx={{ mx: 1 }}>{item.quantidade}</Typography>
              <IconButton onClick={() => incrementar(item)}><AddIcon /></IconButton>
            </Box>
          </Box>
        ))}
      </Box>

      <Button onClick={onClose} sx={{ mt: 2, color: "red", fontWeight: "bold" }}>Adicionar mais itens</Button>
      <Divider sx={{ my: 3 }} />

      {loading && <CircularProgress sx={{ position: "absolute", top: "50%", left: "50%" }} />}
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <TextField
          label="CEP"
          variant="outlined"
          value={cep}
          onChange={handleCepChange}
          inputProps={{ maxLength: 8 }}
          sx={{ flex: 1, mr: 2 }}
        />
        <Button variant="contained" onClick={calcularFrete}>Calcular Frete</Button>
      </Box>
      {mensagemFrete && <Typography color="error" sx={{ mb: 2 }}>{mensagemFrete}</Typography>}

      <Typography variant="h6" fontWeight="bold">Resumo de valores</Typography>
      <Box sx={{ mt: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
          <Typography variant="body1">Subtotal</Typography>
          <Typography variant="body1">R$ {subtotal.toFixed(2)}</Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
          <Typography variant="body1">Taxa de entrega</Typography>
          <Typography variant="body1">R$ {infoEntrega.taxa?.toFixed(2) || "-"}</Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          <Typography variant="h6" fontWeight="bold">Total</Typography>
          <Typography variant="h6" fontWeight="bold">R$ {(subtotal + (infoEntrega.taxa || 0)).toFixed(2)}</Typography>
        </Box>
      </Box>

      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          backgroundColor: "white",
          boxShadow: "0 -2px 10px rgba(0,0,0,0.1)",
          padding: 0,
          display: "flex",
          justifyContent: "center",

        }}
      >
        <Button fullWidth variant="contained" color="primary" sx={{ backgroundColor: "red", color: "white", fontWeight: "bold" }} onClick={continuar}>
          Continuar
        </Button>
      </Box>
    </Box>
  );
};

export default CartStep;
