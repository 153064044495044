import React from "react";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";

function ProductCard({ imagem, title, descricao, preco, onClick }) {
  return (
    <Card
      sx={{
        display: "flex",
        height:150,
        mt: 1,
        cursor: "pointer",
        boxShadow: 3,
        "&:hover": {
          boxShadow: 6,
        },
      }}
      onClick={onClick}
    >
      {/* Imagem do Produto */}
      <CardMedia
        component="img"
        sx={{ width: 100,borderRadius: 1 }}
        src={imagem}
        alt={title}
      />

      {/* Conteúdo do Produto */}
      <CardContent>
        <Typography variant="body1" fontWeight="bold">
          {title}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            display: "-webkit-box", // Configuração para limitar as linhas
            WebkitLineClamp: 2, // Limita o texto a 2 linhas
            WebkitBoxOrient: "vertical",
            overflow: "hidden", // Esconde o texto excedente
            textOverflow: "ellipsis", // Adiciona "..."
          }}
        >
          {descricao}
        </Typography>
        <Typography
          variant="h6"
          sx={{ mt: 1, fontWeight: "bold" }}
        >
          R$ {preco.toFixed(2).replace(".", ",")}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default ProductCard;
