import api from "./axiosInstance";

export const criarCategoria = async (dados) => {
  const response = await api.post("/categoriacardapio", dados);
  return response.data;
};

export const listarCategorias = async (estabelecimento_id) => {
  const response = await api.get(`/categoriacardapio/${estabelecimento_id}`);
  return response.data;
};

export const atualizarCategoria = async (id, dados) => {
  const response = await api.put(`/categoriacardapio/${id}`, dados);
  return response.data;
};

export const deletarCategoria = async (id) => {
  const response = await api.delete(`/categoriacardapio/${id}`);
  return response.data;
};


export const listarCategoriasComItens = async (link) => {
    const response = await api.get(`/categoriacardapioitens/${link}`);
    //console.log(response.data)
    return response.data;
  };