import React, { createContext, useContext, useReducer, useEffect } from "react";

// Tempo de expiração do carrinho em milissegundos (1 hora = 3600000ms)
const CART_EXPIRATION_TIME = 3600000;

// Estado inicial do carrinho
const initialState = (() => {
  const savedCart = JSON.parse(localStorage.getItem("cartState"));

  if (savedCart) {
    const currentTime = Date.now();
    const cartAge = currentTime - savedCart.timestamp;

    // Verifica se o carrinho expirou
    if (cartAge > CART_EXPIRATION_TIME) {
      localStorage.removeItem("cartState"); // Remove o carrinho expirado
      return { items: [], total: 0, timestamp: currentTime };
    }

    return savedCart;
  }

  return { items: [], total: 0, timestamp: Date.now() };
})();

// Funções do carrinho
const cartReducer = (state, action) => {
  const currentTime = Date.now();

  switch (action.type) {
    case "ADD_ITEM":
      const signature = JSON.stringify({
        id: action.payload.id,
        adicionais: action.payload.adicionais.map((adicional) => ({
          id: adicional.id,
          quantidade: adicional.quantidade,
        })),
      });

      const existingItemIndex = state.items.findIndex(
        (item) => item.signature === signature
      );

      let updatedItems;

      if (existingItemIndex !== -1) {
        // Atualiza a quantidade do item existente
        updatedItems = state.items.map((item, index) =>
          index === existingItemIndex
            ? {
                ...item,
                quantidade: item.quantidade + action.payload.quantidade,
              }
            : item
        );
      } else {
        // Adiciona um novo item ao carrinho
        updatedItems = [
          ...state.items,
          { ...action.payload, signature, quantidade: action.payload.quantidade },
        ];
      }

      // **Correção no cálculo do total**: Soma o preço do item e de seus adicionais
      const updatedTotal = updatedItems.reduce((total, item) => {
        const adicionaisTotal = item.adicionais.reduce(
          (sum, adicional) => sum + adicional.preco * adicional.quantidade,
          0
        );
        return total + (item.preco + adicionaisTotal) * item.quantidade;
      }, 0);

      return {
        ...state,
        items: updatedItems,
        total: updatedTotal,
        timestamp: currentTime,
      };

    case "REMOVE_ITEM":
      const filteredItems = state.items.filter(
        (item) => item.signature !== action.payload.signature
      );

      const updatedRemoveTotal = filteredItems.reduce((total, item) => {
        const adicionaisTotal = item.adicionais.reduce(
          (sum, adicional) => sum + adicional.preco * adicional.quantidade,
          0
        );
        return total + (item.preco + adicionaisTotal) * item.quantidade;
      }, 0);

      return {
        ...state,
        items: filteredItems,
        total: updatedRemoveTotal,
        timestamp: currentTime,
      };

    case "CLEAR_CART":
      return { items: [], total: 0, timestamp: currentTime };

    default:
      return state;
  }
};

// Criar o contexto
const CartContext = createContext();

// Provider para o carrinho
export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);

  // Salva o estado no localStorage sempre que ele for atualizado
  useEffect(() => {
    localStorage.setItem("cartState", JSON.stringify(state));
  }, [state]);

  const addItem = (item) => {
    dispatch({ type: "ADD_ITEM", payload: item });
  };

  const removeItem = (signature) => {
    dispatch({ type: "REMOVE_ITEM", payload: { signature } });
  };

  const clearCart = () => {
    dispatch({ type: "CLEAR_CART" });
  };

  return (
    <CartContext.Provider
      value={{
        items: state.items,
        total: state.total,
        addItem,
        removeItem,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

// Hook para usar o contexto do carrinho
export const useCart = () => {
  return useContext(CartContext);
};
